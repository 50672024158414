import React, { Component, Fragment } from 'react';
import RelHeader from '../components/Header';
import smoothscroll from 'smoothscroll-polyfill';

class BasePage extends Component {
	constructor(props, context) {
		super(props, context);
		smoothscroll.polyfill();
	}
	render() {
		return (
			<Fragment>
				<RelHeader />
			</Fragment>
		)
	}
}

export default BasePage;
