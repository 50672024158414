import React from 'react';
import RelBaseComponent from './RelBase';

class Testimonial extends RelBaseComponent {

	render() {
		const { text, sig } = this.props;
		return (
			<div className='testimonial'>
				<div className='container'>
					<p>
						{text}
					</p>
					<span>{sig}</span>
				</div>
			</div>
		)
	}
}

export default Testimonial;
