import React, { createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import CONST from '../common/Constants';
import BasePage from './_base';
import PageSlider from '../components/PageSlider';
import ContactUs from '../components/ContactUs';

import '../css/App.css';
import '../css/main.css';

const imgLogosFaded = CONST.imageBaseUrl + 'logos_faded_transparent.png'

const refs = [
	createRef(),
	createRef(),
	createRef(),
	createRef(),
	createRef()
];

// get data
const pageData = require('../data/SlidePageData.json');

class HomePage extends BasePage {
	constructor(props, context) {
		super(props, context);

		this.state = {
			currentPage: -1,
			currentCarouselIndex: 0,
			maxCarouselIndex: pageData.length - 1,
			learnMoreOnSamePage: true,
			learnMoreIndex: -1,
			pageIntroTitle: pageData[0].title,
			pageIntroContent: pageData[0].intro
		}

		this.widePageLayout = 1000;

		this.newState = {};
		this.newStateTimer = null;
		this.newStateTimerInterval = 250;

		this.stageStateUpdate = this.stageStateUpdate.bind(this);
		this.handleScrollClick = this.handleScrollClick.bind(this);
		this.handleScrolltop = this.handleScrolltop.bind(this);
		this.handleScrollUp = this.handleScrollUp.bind(this);
		this.handleScrollDown = this.handleScrollDown.bind(this);
		this.handleCarouselSelect = this.handleCarouselSelect.bind(this);
		this.handleLearnMore = this.handleLearnMore.bind(this);
		this.startCarousel = this.startCarousel.bind(this);
		this.setCurrentSlide = this.setCurrentSlide.bind(this);
		this.setNextSlide = this.setNextSlide.bind(this);
		this.updateSize = this.updateSize.bind(this);
	}

	getDim(el) {
		let hght = el.clientHeight;
		for (var lx = 0, ly = 0;
			el != null;
			lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
		return { x: lx, y: ly, hght: hght };
	}

	scrollToElem(el) {
		let elemDim = this.getDim(el);
		if (elemDim === null) return;
		// FOR SOME REASON WE NEED TO HANG ON A HALF SECOND TO SCROLL
		let timer = setInterval(() => {
			window.scrollTo({ behavior: 'smooth', left: 0, top: elemDim.y - 150 });
			clearInterval(timer);
		}, 500);
	}

	stageStateUpdate(inState) {
		this.newState = Object.assign(this.newState || {}, inState);
		if (this.newStateTimer != null) {
			clearInterval(this.newStateTimer);
		}
		this.newStateTimer = setInterval(() => {
			clearInterval(this.newStateTimer);
			this.setState({
				...this.newState
			});
		}, this.newStateTimerInterval);
	}

	setCurrentSlide(index) {
		this.stageStateUpdate({
			currentCarouselIndex: index,
			pageIntroTitle: pageData[index].title,
			pageIntroContent: pageData[index].intro
		});
	}

	setNextSlide() {
		let newIndex = this.state.currentCarouselIndex < this.state.maxCarouselIndex ? this.state.currentCarouselIndex + 1 : 0;
		this.setCurrentSlide(newIndex);
	}

	stopCarousel() {
		clearInterval(this.carouselTimer);
	}

	startCarousel() {
		clearInterval(this.carouselTimer);
		this.carouselTimer = setInterval(() => {
			this.setNextSlide();
		}, 6000);
	}

	updateSize() {
		// for immediate re-render
		this.setState({
			currentPage: this.state.currentPage
		})
	}

	handleScrollClick(direction) {
		let currPage = this.state.currentPage + (direction === 'up' ? 1 : -1);
		if (currPage >= refs.length) {
			currPage = 0;
		}
		if (currPage < 0) {
			currPage = refs.length - 1;
		}

		let elem = document.getElementById('slide-page-0');
		this.scrollToElem(elem);
		this.stageStateUpdate({
			currentPage: currPage
		});
	}

	handleLearnMore() {
		if (this.state.learnMoreIndex >= 0) {
			this.handleScrolltop();
		} else {
			// stop the paging of the carousel
			this.stopCarousel();
			// load the current item into the view more area. 
			// scroll it to the top when it's loaded
			this.setState({
				learnMoreIndex: this.state.currentCarouselIndex
			}, () => {
				let elem = document.getElementById('learnmorediv');
				this.scrollToElem(elem);
			});
		}
	}

	handleScrollUp() {
		this.handleScrollClick('up');
	}

	handleScrollDown() {
		this.handleScrollClick('down');
	}

	handleScrolltop() {
		window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
		var timer = setInterval(() => {
			this.stageStateUpdate({
				learnMoreIndex: -1
			});
			clearInterval(timer);
			this.setNextSlide();
			this.startCarousel();
		}, 500);
	}

	handleCarouselSelect(selectedIndex, e) {
		console.log("handleCarouselSelect");
		this.setCurrentSlide(selectedIndex);
		this.stageStateUpdate({
			learnMoreIndex: -1
		});
		this.startCarousel();
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.updateSize);

		this.startCarousel();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateSize);
	}

	render() {

		return (
			<Fragment>
				<div className="contenthome" style={{
					perspective: "1px",
					width: "100%"
				}}>
					<div className="contentcenter">
						<Carousel className="homecarousel carousel-fade" pauseOnHover={true} interval={null} activeIndex={this.state.currentCarouselIndex} indicators={true} onSelect={this.handleCarouselSelect} style={{ backgroundImage: `url(${imgLogosFaded})`, backgroundPositionY: "center", backgroundRepeat: "no-repeat" }}>
							{pageData.map((pages, index) =>
								<Carousel.Item key={index}>
									{window.innerWidth < this.widePageLayout && <h1>{pages.title}</h1>}
									<div className="contentcompanyintro" style={{
										backgroundImage: `url(${CONST.imageBaseUrl + pages.backgroundImage})`,
										backgroundPosition: `${pages.backgroundPosition}`,
										backgroundRepeat: `${pages.backgroundRepeat}`,
										backgroundSize: `${pages.backgroundSize}`
									}}>
										{window.innerWidth < this.widePageLayout &&
											<div>
												<p>{pages.intro}</p>
												{this.state.learnMoreOnSamePage && <button onClick={this.handleLearnMore}>{this.state.learnMoreIndex >= 0 ? "Next" : "About " + pages.title}</button>}
												{!this.state.learnMoreOnSamePage && <Link to={`/Home/LearnMore/${index}`}>{this.state.learnMoreIndex >= 0 ? "Next" : "About " + pages.title}</Link>}
											</div>}
									</div>
								</Carousel.Item>
							)}
						</Carousel>

						{window.innerWidth > this.widePageLayout &&
							<div className="contentcompanyintroright">
								<h2>{this.state.pageIntroTitle}</h2>
								<p>{this.state.pageIntroContent}</p>
								{this.state.learnMoreOnSamePage && <button onClick={this.handleLearnMore}>{this.state.learnMoreIndex >= 0 ? "Next" : "About " + this.state.pageIntroTitle}</button>}
							</div>
						}
					</div>
					<div className="contentcenter">
						{this.state.learnMoreOnSamePage && this.state.learnMoreIndex >= 0 &&
							<div id="learnmorediv">
								<hr />
								{this.state.learnMoreIndex >= 0 && <PageSlider pagesData={pageData[this.state.learnMoreIndex]} />}
								<ContactUs />
								<button type='button' title="Back to Top" onClick={this.handleScrolltop} style={{ display: 'block', margin: '5px', visibility: 'visible' }} ><img src={CONST.imageBaseUrl + 'top.png'} alt='top' onClick={this.handleScrolltop} /></button>
							</div>
						}
						<div style={{ height: 25 }} />
					</div>
				</div>
			</Fragment>
		)
	}
}

export default HomePage;
