import React from 'react';
import { Parallax } from 'react-parallax';

import CONST from '../common/Constants';
import RelBaseComponent from "./RelBase";

const insideStyle = {
	background: "white",
	borderRadius: "0.75em",
	boxShadow: "2px 2px 2px #9E9E9E",
	padding: 20,
	position: "absolute",
	overflow: "auto",
	top: "50%",
	left: "30%",
	transform: "translate(-30%,-50%)",
	maxHeight: "80%",
	fontSize: "1.2w"
}

class SlidePage extends RelBaseComponent {
	isScrolling;
	constructor(props, context) {
		super(props, context);
		this.handleScroll = this.handleScroll.bind(this);
		this.animHeader = this.animHeader.bind(this);
		this.updateSize = this.updateSize.bind(this);

		this.isScrolling = false;

		this.state = {
			pageHeight: 0
		}
	}

	getViewCenterY() {
		return document.documentElement.scrollTop + window.innerHeight / 2;
	}

	getElemCenterY(elem) {
		let elemDim = this.get2Pos(elem);
		return elemDim.y + elemDim.hght / 2;
	}

	get2Pos(el) {
		let hght = el.clientHeight;
		for (var lx = 0, ly = 0;
			el != null;
			lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
		return { x: lx, y: ly, hght: hght };
	}

	isOnScreen(elem) {
		let elemDim = this.get2Pos(elem);
		let onScreen = document.documentElement.scrollTop + window.innerHeight > elemDim.y && document.documentElement.scrollTop < elemDim.y + elemDim.hght

		return (onScreen);
	}

	doAnimElem(elem, direction) {
			elem.style['transition'] = 'transform 0.75s ease-out';
			elem.style['transform'] = 'translateY(' + (direction === 'up' ? '-300px' : '0px');
	}

	animHeader = (parentElem, headerElem) => {
		if (this.getViewCenterY() > this.getElemCenterY(parentElem)) {
			this.doAnimElem(headerElem, 'dn');
		} else {
			this.doAnimElem(headerElem, 'up');
		}
	}

	runScroll() {
		let headerElem = document.getElementById("moving-header-" + this.props.slidePageItemId);
		let parentElem = headerElem.offsetParent;

		if (true || this.isOnScreen(parentElem)) {
			this.animHeader(parentElem, headerElem);
		}
	}

	handleScroll() {
		if (!this.isScrolling) {
			this.isScrolling = true;
			this.runScroll();
			setTimeout(() => {
				this.isScrolling = false;
			}, 100);
		}
	}

	updateSize() {
		this.setState({
			pageHeight: 375 // Math.max(window.innerHeight - 250, 500)
		});
	}

	componentDidMount() {
		this.handleScroll();
		this.updateSize();
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.updateSize);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.updateSize);
	}

	render() {
		const {
			pageData,
			slidePageItemId,
			slideRef,
			height,
			strength,
			blur
		} = this.props;
		return (
			<div id={"slide-page-" + slidePageItemId} key={slidePageItemId} ref={slideRef} style={{ height: this.state.pageHeight }}>
				<Parallax bgImage={CONST.imageBaseUrl + pageData.bgImg} strength={strength} blur={blur}>
					<div style={{ height: height }}>
						<div id={"para-" + slidePageItemId} style={insideStyle} dangerouslySetInnerHTML={{ __html: pageData.para }}>
						</div>
					</div>
					<div id={"moving-header-" + slidePageItemId} className={'headerStyle'}>
						{pageData.header !== "" && <h3><span>&nbsp;{pageData.header}&nbsp;</span></h3>}
					</div>
				</Parallax >
			</div>
		)
	}
}

export default SlidePage;
