import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';

import CONST from '../common/Constants';
import BasePage from './_base';
import Testimonial from '../components/Testimonial';

class AboutPage extends BasePage {


	render() {
		const picList = ['Mike_0085.JPG', 'Mike_0089.JPG', 'Mike_0092.JPG', 'Mike_Pic_1_sm.png'];
		const whichPic = picList[Math.floor(Math.random()*4)];
		return (
			<Fragment>
				<div className="row" >
					<div className="column">
						<h1 style={{ color: "#EFF" }}>Reliance Interactive</h1>
						<h3>President<br />
							Michael Baker<br />
							1901 South Homer St.<br />
							Pittsburg, KS. 66762<br /></h3>
						<a href="mailto:mike.baker@RelianceInteractive.com">mike.baker@RelianceInteractive.com</a>
						<br />
						<h3>Work With Us<br /></h3>
						<a href="mailto:careers@RelianceInteractive.com">careers@RelianceInteractive.com</a>
						<br />
						<h3>WebMaster<br /></h3>
						<a href="mailto:info@RelianceInteractive.com">info@RelianceInteractive.com</a>
						<br />
						<hr />
						<Testimonial text='Simply put, whenever I start a project that requires system architecture and the team to support it - Mike is the first person I call. He is a master of system design and data architecture, and he has the ability to apply that knowledge to any environment I throw at him. We’ve built applications for desktop in Authorware and Adobe AIR, for mobile in Xcode, Objective C, and Swift, and for web in JavaScript, HTML5, and AWS. In each scenario, Mike’s work elevated the project architecture with the system infrastructure needed to sustain and deploy the application.' sig='Dan Carr: Dan Carr Design' />
						<hr />
						<Testimonial text="Michael is a person that can't be stopped. No task, no matter how difficult, how impossible, how overwhelming can stop Michael from reaching his goal. Asked to tackle problems that many others considered impossible, Michael always found innovative ways to solve those engineering problems. And while others around him were cracking under the pressure, Michael's cool, steady, friendly demeanor never wavered." sig='Jamil Zainasheff: Product Manager Macromedia' />
					</div>
					<div className="column">
						<h2>A few of our clients</h2><br />
						<img alt="past clients" src={CONST.imageBaseUrl + 'Logos.png'} style={{ width: "100%", maxWidth: "520px", maxHeight: "390px" }} />
						<h3>Find me at:</h3>
						<iframe src="https://www.codeproject.com/members/flair/2820276" title="CodeProject"
							height="200" width="250" scrolling="no" frameBorder="no"></iframe>
						<div className="LI-profile-badge" data-version="v1" data-size="medium" 
							data-locale="en_US" data-type="horizontal" data-theme="dark" data-vanity="michael-v-baker"
							style={{ margin: "20px 0px" }}>
							<a className="LI-simple-link" href='https://www.linkedin.com/in/michael-v-baker?trk=profile-badge'>Michael Baker on Linked In</a>
						</div>
						<a href="https://github.com/mikevbaker" target='_blank' rel='noopener noreferrer'>GitHub Repo</a>
					</div>
					<MediaQuery query="(min-width: 1001px)">
						<div className="column">
							<h2>About Mike</h2><br />
							<p>
								For over 25 years I&#39;ve been involved in programming applications of many different
								kinds. From 1993 to 2008 I concentrated almost exclusively on applications in the
								eLearning industry. Beginning with developing re-usable templates for eLearning
								content developed in Macromedia Authorware, through data driven eLearning applications
								that automatically adjust their content according to data in a content database,
          			to full scale learning management systems.</p>
							<p>
								I had the great pleasure of working with the fantastic team at Macromedia where I
								was able to work on the tool that I used for so many years... Authorware.
							</p>
							<p>
								This broad range of application development has provided me with experience in
								task analysis, project planning, performance measurement, application
								development, and quality assurance. None of these steps are optional, they each
        				carry their weight in the development of any size application.</p>
							<p>
								These are truly exciting times. Every aspect of application development and web
								development is undergoing constant change and eLearning development is no
								exception. New methods and tools are being developed and improved at rates that
								continue to astound. Reliance strives to be current on technologies to provide
          			leading solutions for the eLearning industry.</p>
							<p>
								Data-driving your website, phone app, or eLearning applications is a key to keeping
								long term costs in control. Whether your database is MySQL with PHP, or SQL server and
								.NET, or even Amazon Aurora serverless RDS, Reliance can provide the solution from
          			the database design environment all the way to the end-users screen.</p>

							<div style={{ width: "100%", height: "190px", textAlign: "right" }}>
								<img src={CONST.imageBaseUrl + whichPic} alt="Meet Mike" style={{ margin: '20px 5px', width: '200px' }} />
							</div>
						</div>
					</MediaQuery>
				</div>
			</Fragment>
		)
	}
}

export default AboutPage;