import React from 'react';

var style = {
	backgroundColor: 'rgba(238,238,238,0.9)',
	borderTop: '1px solid #6E6E6E',
	textAlign: 'center',
	padding: '20px',
	position: 'fixed',
	left: '0',
	bottom: '0',
	height: window.innerHeight > 600 ? '60px' : '30px',
	width: '100%',
}

var phantom = {
display: 'block',
padding: '20px',
height: window.innerHeight > 600 ? '60px' : '30px',
width: '100%',
}

function RelFooter({ children }) {
	return (
			<div className='bottompanel'>
					<div style={phantom} />
					<div style={style}>
							{ children }
					</div>
			</div>
	)
}

export default RelFooter;
