import React from 'react';
import Lightbox from 'react-image-lightbox';
import RelBaseComponent from './RelBase';
import CONST from '../common/Constants';

class ProjectDetailPage extends RelBaseComponent {
	constructor(props, context) {
		super(props, context);
		this.handleImageClick = this.handleImageClick.bind(this);
	}

	handleImageClick() {
		const { appDetailInfo } = this.props;
		this.props.imageClickHandler(appDetailInfo.hasOwnProperty('PageNo') ? appDetailInfo.PageNo : 0);
	}

	showSampleLink = false;
	render() {
		const { appDetailInfo } = this.props;
		return (
			<div className="detailPanel" style={{ backgoundColor: 'white' }}>
				{(appDetailInfo.hasOwnProperty('AppImageUrl')) && 
					<img alt={appDetailInfo.AppImageUrl.replace("~/images/", CONST.imageBaseUrl)} src={appDetailInfo.AppImageUrl.replace("~/images/", CONST.imageBaseUrl)} onClick={this.handleImageClick} />
				} 
				{(appDetailInfo.hasOwnProperty('AppPageImageUrl')) &&
					<img alt={appDetailInfo.AppPageImageUrl.replace("~/images/", CONST.imageBaseUrl)} src={appDetailInfo.AppPageImageUrl.replace("~/images/", CONST.imageBaseUrl)} onClick={this.handleImageClick} />
				}
				{(appDetailInfo.hasOwnProperty('AppDescription')) &&
					<div className="detailHtml" dangerouslySetInnerHTML={{ __html: appDetailInfo.AppDescription }}></div>
				}  
				{(appDetailInfo.hasOwnProperty('AppPageText')) &&
					<div className="detailHtml" dangerouslySetInnerHTML={{ __html: appDetailInfo.AppPageText }}></div>
				}
				{(this.showSampleLink 
					&& appDetailInfo.hasOwnProperty('AppLaunchUrl') && appDetailInfo.AppLaunchUrl && appDetailInfo.AppLaunchUrl.length > 0) &&
					<a href={CONST.sampleBaseUrl + appDetailInfo.AppLaunchUrl} >{appDetailInfo.AppLaunchUrl}</a>
				}
			</div>
		);
	}
}

class ProjectDetails extends RelBaseComponent {

	constructor(props, context) {
		super(props, context);
		this.state = {
			photoIndex: 0,
			isOpen: false
		}
		this.imageClickHandler = this.imageClickHandler.bind(this);
	}

	imageClickHandler(arg) {
		this.setState({
			isOpen : true,
			photoIndex: arg
		})
	}

	getProjectImages(project) {
		let images = [];
		images = project.AppSampleInfoPages.map((item, index) => {
			return item.AppPageImageUrl.replace("~/images/", CONST.imageBaseUrl)
		});
		images.splice(0, 0, project.AppImageUrl.replace("~/images/", CONST.imageBaseUrl));
		return images;
	}

	render() {
		const { appDetails } = this.props;
		const { photoIndex, isOpen } = this.state;
		const appImages = this.getProjectImages(appDetails);

		return (
			
			<div className="detailContainer">
				<ProjectDetailPage key={appDetails.Id} appDetailInfo={appDetails} imageClickHandler={this.imageClickHandler} />
				{appDetails.AppSampleInfoPages.length > 0 && appDetails.AppSampleInfoPages.map((page, index) =>
					<ProjectDetailPage key={page.Id} appDetailInfo={page} imageClickHandler={this.imageClickHandler} />
				)}
				{appDetails.length === 0 && <div className="detailPanel">No Project Selected</div>}
				{isOpen &&
					<Lightbox
						mainSrc={appImages[photoIndex]}
						prevSrc={photoIndex > 0 ? appImages[(photoIndex - 1)] : ''}
						nextSrc={photoIndex < appImages.length - 1 ? appImages[(photoIndex + 1)] : ''}
						onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: photoIndex > 0 ? photoIndex - 1 : photoIndex
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: photoIndex < appImages.length - 1 ? photoIndex + 1 : photoIndex
              })
						}
					/>
					}
			</div>
		);
	}
}

export default ProjectDetails;
