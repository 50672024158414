import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import CONST from './common/Constants';

import './css/App.css';
import './css/main.css';

import RelHeader from './components/Header';
import RelFooter from './components/Footer';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import NotFound from './pages/NotFound';
import MeetMike from './pages/MeetMike';
import ProjectExplorer from './pages/ProjectExplorer';
import LearnMore from './pages/LearnMore';

class App extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			appCategories: []
		}

		this.loadCategories = this.loadCategories.bind(this);
	}

	// wake up the database web service
	loadCategories() {
		axios
			.get(`${CONST.dataBaseUrl}odata/AppCategories`)
			.then(response => {
				this.setState({
					appCategories: response.data.value
				});
			})
	}

	componentDidMount() {
		this.loadCategories();
	}

	render() {
		return (
			<Router>
				<Fragment>
					<RelHeader />
					<div className="newNav">
						<ul>
							<li>
								<NavLink activeClassName="activeMenu" exact to="/">Home</NavLink>
							</li>
							<li>
								<NavLink activeClassName="activeMenu" to="/about">About</NavLink>
							</li>
							<li>
								<NavLink activeClassName="activeMenu" to="/experience">Experience</NavLink>
							</li>
						</ul>
					</div>
					<div><hr style={{ margin: "0px" }}></hr></div>
					<div className="contentmain">
						<Route path="/" exact component={HomePage} />
						{/* <Route path="/experience" component={ProjectExplorer} /> */}
						<Route path="/about" component={AboutPage} />
						<Route path="/Home/MeetMike/" component={MeetMike} />
						<Route path="/Home/LearnMore/:learnMoreIndex" component={LearnMore} />
						<Route path="/experience/:projectId?" component={ProjectExplorer} />
						<Route path="/notfound" component={NotFound} />
					</div>
					<RelFooter>
						<a href="mailto:Info@RelianceInteractive.com">Contact Us</a>&nbsp;||&nbsp;
						<a href="mailto:Feedback@RelianceInteractive.com">Feedback</a>&nbsp;||&nbsp;
						<Link to="/Home/MeetMike/">Meet Mike</Link>
						{window.innerHeight > 600 && <br />}
						&nbsp;© 2007-2019 Reliance Interactive Training Solutions Inc.
					</RelFooter>
				</Fragment>
			</Router>
		);
	}
}

export default App;
