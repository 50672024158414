import React from 'react';
import MediaQuery from 'react-responsive';
import RelBaseComponent from './RelBase';
import CONST from '../common/Constants';
import Select from 'react-select';
import {setStyleRules} from '../common/StyleUtil';

class ProjectThumbnail extends RelBaseComponent {
	render() {
		return <img src={this.props.imageUrl} alt={this.props.imageUrl} />
	}
}

class ProjectRow extends RelBaseComponent {
	showSampleIndicator = false;
	constructor(props) {
		super(props);
		this.handleProjectClick = this.handleProjectClick.bind(this);
	}
	handleProjectClick() {
		let clickedProject = {
			label: this.props.project.AppName,
			value: this.props.project.Id
		}
		this.props.projectClickHandler(clickedProject);
	}
	render() {
		const project = this.props.project
		return (
			<li key={project.Id} onClick={this.handleProjectClick}>
				<span className='appThumbs'>{project.AppName}</span>
				{(project.AppLaunchUrl && this.showSampleIndicator) && <img src={CONST.imageBaseUrl + 'star.png'} alt='Sample' style={{ width: '0.75em', float: 'right' }} />}
				<ProjectThumbnail imageUrl={project.AppImageUrl.replace("~/images/", CONST.imageBaseUrl)} />
			</li>
		)
	}
}

class ProjectList extends RelBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			currentCategories: [],
			currentProject: {},
			sampleFilter: false,
			changedAnim: false
		}
		this.handleCategorySelect = this.handleCategorySelect.bind(this);
		this.handleSampleFilterCheck = this.handleSampleFilterCheck.bind(this);
	}
	handleCategorySelect = (selectedOption) => {
		this.props.categorySelectHandler(selectedOption);
		//		this.setState({ currentCategories: selectedOption });
	}
	handleSampleFilterCheck = (eventArg) => {
		this.props.sampleFilterCheckHandler(eventArg);
		this.setState({ sampleFilter: eventArg.target.checked });
	}
	handleProjectSelect = (selectedOption) => {
		this.props.projectClickHandler(selectedOption);
		this.setState({ currentProject: selectedOption });
	}
	componentDidUpdate = () => {
		if (!this.state.changedAnim) {
			let propList = [
				"animation-name",
				"animation-duration",
				"animation-delay",
				"animation-iteration-count",
				"-webkit-animation-name",
				"-webkit-animation-duration",
				"-webkit-animation-delay",
				"-webkit-animation-iteration-count"
			];
			let valueList = [
				"animbackgroundcolor",
				"1.5s",
				"1.5s",
				"2",
				"animbackgroundcolor",
				"1.5s",
				"1.5s",
				"2"
			]
			setStyleRules(".css-vj8t7z", propList, valueList);
			this.state.changedAnim = true;
		}
	}
	componentDidMount = () => {
		let timer = setInterval(() => {
			clearInterval(timer);
			setStyleRules(".css-vj8t7z", ["animation-name","-webkit-animation-name"], ["",""]);
		}, 5000);
	}

	render() {
		const {
			appCategories,
			currentCategories,
			projectList,
			currentProject,
			sampleFilter,
			showSampleFilter,
			sampleFilterCheckHandler,
			projectClickHandler
		} = this.props;
		const categoryOptions = appCategories.map((category, index) => {
			return { value: category.Id, label: category.Name };
		});
		categoryOptions.unshift({ value: 0, label: "All" });
		const projectOptions = projectList.map((project, index) => {
			return { value: project.Id, label: project.AppName };
		});

		return (
			<div id="project-list" className="projectlist" title="Select categories and a project to see details.">
				<Select id="project-list-select-cat"
					isSearchable={false}
					isMulti
					onChange={this.handleCategorySelect}
					options={categoryOptions}
					value={currentCategories}
					placeholder="Select Categories..." />
				{showSampleFilter &&
					<div style={{ marginLeft: 10 }}>
						<input id="sampleFilter" name="sampleFilter" type="checkbox" onChange={sampleFilterCheckHandler} checked={sampleFilter} />
						<label htmlFor="sampleFilter" style={{ marginLeft: 5 }}>Samples only</label>
					</div>}
				<MediaQuery query="(min-width: 701px)">
					<div>
						<span style={{ display: "block", margin: "0.5em 1em 0em 2em", fontSize: "medium", color: "#dfdfdf", position: "fixed" }}>Click Project</span>
						<ul className='appthumbs'>
							{projectList.length > 0 && projectList.map((project, index) =>
								<ProjectRow key={project.Id} project={project} projectClickHandler={projectClickHandler} />
							)}
							{projectList.length === 0 && <span style={{ margin: "2em 3em", display: "inline-block" }}>No items found</span>}
						</ul>
					</div>
				</MediaQuery>
				<MediaQuery query="(max-width: 700px)">
					<Select id="project-list-select-project"
						isSearchable={false}
						onChange={this.handleProjectSelect}
						options={projectOptions}
						value={currentProject}
						placeholder="Select Project..." />
				</MediaQuery>
			</div>
		)
	}
}

export default ProjectList;
