import React from 'react';

import CONST from '../common/Constants';
import BasePage from './_base';

class MeetMike extends BasePage {

	render() {
		window.scrollTo(0,0);
		return (
			<div style={{width: "70%", margin: "150px auto 10px auto"}}>
				<p>
					For over 25 years I&#39;ve been involved in programming applications of many different
					kinds. From 1993 to 2008 I concentrated almost exclusively on applications in the
					eLearning industry. Beginning with developing re-usable templates for eLearning
					content developed in Macromedia Authorware, through data driven eLearning applications
					that automatically adjust their content according to data in a content database,
          to full scale learning management systems.</p>
				<p>
					I had the great pleasure of working with the fantastic team at Macromedia where I 
					was able to work on the tool that I used for so many years... Authorware.
				</p>
				<p>
					This broad range of application development has provided me with experience in
					task analysis, project planning, performance measurement, application
					development, and quality assurance. None of these steps are optional, they each
        	carry their weight in the development of any size application.</p>
				<p>
					These are truly exciting times. Every aspect of application development and web
					development is undergoing constant change and eLearning development is no
					exception. New methods and tools are being developed and improved at rates that
					continue to astound. Reliance strives to be current on technologies to provide
          leading solutions for the eLearning industry.</p>
				<p>
					Data-driving your website, phone app, or eLearning applications is a key to keeping 
					long term costs in control. Whether your database is MySQL with PHP, or SQL server and 
					.NET, or even Amazon Aurora serverless RDS, Reliance can provide the solution from 
          the database design environment all the way to the end-users screen.</p>

				<div style={{width: "100%", height: "190px", textAlign: "right"}}>
					<img src={CONST.imageBaseUrl + 'Mike_Pic_1_sm.png'} alt="Meet Mike" style={{margin: '20px 5px', width: '150px', height: '186px'}} />
				</div>

			</div>
		)
	}

}

export default MeetMike;

