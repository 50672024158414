export function setStyleRule(ruleClass, property, value) {
	for (var s = 0; s < document.styleSheets.length; s++) {
		var sheet = document.styleSheets[s];
		try {
			var rules = sheet.cssRules ? sheet.cssRules : sheet.rules;
			if (rules == null) return null;
			for (var i = 0; i < rules.length; i++) {
				if (rules[i].selectorText === ruleClass) {
					rules[i].style[property] = value;
				}
			}
		} catch(e) {
			// in Firefox we can't scan style sheets from another domain so we get a securityError. 
			// We ignore this cause it's only our domain css that we want to change
			if (e.name !== 'SecurityError')
				throw e;
		}
	}
	return null;
}
export function setStyleRules(ruleClass, properyList, valueList) {
	if (properyList.length !== valueList.length) {
		throw new Error("The two lists must be equal size");
	}
	for (var i=0; i < properyList.length; i++) {
		setStyleRule(ruleClass, properyList[i], valueList[i]);
	}
}
