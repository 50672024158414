

const CONST = {
	baseUrl : 'https://www.relianceinteractive.com', 
	dataBaseUrl : 'https://www.relianceinteractive.com/RelianceOdata/',
	// dataBaseUrl : 'http://webapiodataservicereliance-dev.us-east-1.elasticbeanstalk.com/',
	imageBaseUrl : 'https://www.RelianceInteractive.com/commonimg/',
	sampleBaseUrl : 'https://www.RelianceInteractive.com/samples/'
}

export default CONST;
