import React from 'react';
import MediaQuery from 'react-responsive';

import CONST from '../common/Constants';
import RelBaseComponent from './RelBase';

class RelHeader extends RelBaseComponent {
	render() {
		const logoImage = CONST.imageBaseUrl + "logo_small_WOshadow_transparent.gif";
		return (
			<div className="toppanel">
				<MediaQuery query="(min-width: 700px)">
					<a href="/">
						<img alt="Reliance Interactive"
							src={logoImage}
							className="logo" />
					</a>
				</MediaQuery>
				<MediaQuery query="(max-width: 700px)">
					<a href="/">
						<img alt="Reliance Interactive"
							width="33px"
							src={logoImage}
							className="logo" />
					</a>
				</MediaQuery>
				<span className="companyname">Reliance</span>
				<span className="companynameparttwo">Interactive Solutions</span>
			</div>
		)
	}
}

export default RelHeader;