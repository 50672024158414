import React, { Fragment } from 'react';
import BasePage from './_base';
import CONST from '../common/Constants';

class NotFoundPage extends BasePage {
	render() {
		return (
			<Fragment>
				<div className="contentcenter" style={{margin: "0 auto", width: "60%", display: "block"}}>
					<h1 style={{	color: "#EFF"}}>OOPS!</h1>
					<h3>This is not the page you&#39;re looking for...</h3><br />
					<p>Something went wrong and we can&#39;t find the page you wanted</p>
					<br />
					<h3>Click <a href={CONST.baseUrl}>here</a> to run home.</h3>
					<br />
					<h3>WebMaster<br />
					<a href="mailto:info@RelianceInteractive.com">info@RelianceInteractive.com</a></h3>
					<br />
				</div>
			</Fragment>
		)
	}
}

export default NotFoundPage;
