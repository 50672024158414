import React from 'react';
import axios from 'axios';
import qs from 'qs';

import '../css/App.css';
import '../css/main.css';

import CONST from '../common/Constants';
// import ReactPage from '../components/ReactPage';
import ProjectList from '../components/ProjectList';
import ProjectDetails from '../components/ProjectDetails';
import BasePage from './_base';

class ProjectExplorer extends BasePage {
	//
	// Life cycle events
	//
	constructor(props, context) {
		super(props, context);
		this.state = {
			userName: "",
			appCategories: [],
			currentCategories: [],
			projects: [],
			sampleFilter: false,
			showSampleFilter: false,
			currentProject: {},
			currentProjectId: 0,
			projectDetails: {},
			projectLoading: true,
			projectLoadId: 0
		};
		this.handleClickProject = this.handleClickProject.bind(this);
		this.handleCategorySelect = this.handleCategorySelect.bind(this);
		this.handleClickSampleFilter = this.handleClickSampleFilter.bind(this);
	};

	componentDidMount() {
		this.loadCategories();
		this.loadProjects();
	}

	//
	// Loading from database (TBD, change to Redux?)
	//
	loadCategories() {
		axios
			.get(`${CONST.dataBaseUrl}odata/AppCategories?$orderby=Name&$filter=IncludeInMenu`)
			.then(response => {
				this.setState({
					appCategories: response.data.value
				});
			})
	}

	loadProjects() {
		let urlString = "";
		if (this.state.showSampleFilter && this.state.sampleFilter) {
			urlString = `${CONST.dataBaseUrl}odata/AppSampleInfoes?$filter=length(AppLaunchUrl) gt 0&$expand=AppSampleInfo_AppCategory&$orderby=Ordinal`;
		} else {
			urlString = `${CONST.dataBaseUrl}odata/AppSampleInfoes?$expand=AppSampleInfo_AppCategory&$orderby=Ordinal`;
		}
		axios
			.get(urlString)
			.then(response => {
				let projectList = this.filterProjectsForSelectedCategory(response.data.value, this.state.currentCategories);
				let currentProject = {};

				if (projectList.length > 0) {
					let projectId = 0;
					if (this.props.location.search.length > 0) {
						const fixedQuery = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
						projectId = parseInt(fixedQuery.projectId);
					}
					// let { projectId } = this.props.match.params;
					// projectId = parseInt(projectId);
					let idx = this.getProjectIndex(projectList, projectId);
					if (idx < 0) {
						idx = 0;
					}
					currentProject = {
						value: projectList[idx].Id,
						label: projectList[idx].AppName
					}
				}
				console.log("currentProjectId=" + currentProject.value);
				this.setState({
					projects: projectList,
					currentProject: currentProject, 
					currentProjectId: currentProject.value
				});
				//	if (currentProject !== 0) {
				this.loadProjectDetails();
				//	}
			});
	}

	loadProjectDetails() {
		let timer = setInterval(() => {
			this.setState({projectLoading: true});
		}, 1000);
		let urlString = `${CONST.dataBaseUrl}odata/AppSampleInfoes(${this.state.currentProjectId})?$expand=AppSampleInfoPages($orderby=PageNo)`
		axios
			.get(urlString)
			.then(response => {
				clearInterval(timer);
				let project = response.data;
				this.setState({
					projectDetails: project,
					projectLoading: false
				});
			});
		window.scrollTo(0,0);
	}

	//
	// Data utilities
	filterProjectsForSelectedCategory(projectList, categories) {
		if (!projectList) return;
		if (projectList === undefined) return;
		if (categories.length === 0) {
			return projectList;
		} else {
			let newProjectList = [];
			let catNums = [];
			categories.forEach(function(elem) {
				catNums.push(elem.value);
			});
			newProjectList = projectList.filter(project =>
				project.AppSampleInfo_AppCategory.filter(e => catNums.includes(e.AppCategoryId)).length > 0);

			return newProjectList;
		}
	}

	logProjects(projectList) {
		console.log("DUMPING PROJECT LIST");
		console.log(projectList);
	}

	getProjectIndex(projectList, id) {
		let result = -1
		for (var idx=0; idx < projectList.length; idx++) {
			if (projectList[idx].Id === id) {
				result = idx;
			}
		}
		if (result > -1)
			console.log("getProjectIndex returning=" + result + " (project.Id = " + projectList[result].Id + " AppName " + projectList[result].AppName + ")");
		return result;
	}

	getCategorySelectItems() {
		const menuItems = this.state.appCategories.map((category, index) => {
			return { value: category.Id, label: category.Name }
		});
		menuItems.unshift({ value: 0, label: "All" });
		return menuItems;
	}

	getProjectSelectItems() {
		const options = this.state.projects.map((project, index) => {
			return { value: project.Id, label: project.AppName };
		});
		return options;
	}

	//
	// Handle user interaction
	//
	handleClickSampleFilter(arg) {
		this.setState({ sampleFilter: arg.target.checked }, () => { this.loadProjects() });
	}
	handleClickProject(arg) {
		this.setState({ currentProject: arg, currentProjectId: arg.value }, () => { this.loadProjectDetails() });
	}
	handleCategorySelect(arg) {
		let newCatList = [];
		if (typeof arg === 'object' && arg !== null) {
			if (!arg.some(e => e.value === 0)) {
				arg.forEach(function(element) {
					newCatList.push(element);
				});
			}
		}
		this.setState({ currentCategories: newCatList }, () => { this.loadProjects() });
	}
	
	render() {
		return (
			<div id="pg_project_explorer" className="projectexplorer">
				<ProjectList
					appCategories={this.state.appCategories}
					currentCategories={this.state.currentCategories}
					projectList={this.state.projects}
					currentProject={this.state.currentProject}
					sampleFilter={this.state.sampleFilter}
					showSampleFilter={this.state.showSampleFilter}
					sampleFilterCheckHandler={this.handleClickSampleFilter}
					projectClickHandler={this.handleClickProject}
					categorySelectHandler={this.handleCategorySelect} />
				{this.state.projectLoading && <div style={{textAlign: "center", marginTop:"200px"}}><h2>Loading...</h2></div>}
				{this.state.projectDetails.hasOwnProperty('Id') && <ProjectDetails className="detailPage" appDetails={this.state.projectDetails} />}
			</div>
		);
	}
}

export default ProjectExplorer;