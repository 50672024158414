import React from 'react';
import BasePage from './_base';
import PageSlider from '../components/PageSlider';
import ContactUs from '../components/ContactUs';

class LearnMore extends BasePage {
	constructor(props, context) {
		super(props, context);

		this.handleBack = this.handleBack.bind(this);
	}

	handleBack() {
		this.props.history.goBack();
	}

	render() {
		const { learnMoreIndex } = this.props.match.params;
		// get data
		let pageData = require('../data/SlidePageData.json');

		return(
			<div className="contentcenter" style={{width: "80%", margin: "0px 50px"}} >
				{learnMoreIndex >= 0 && <PageSlider pagesData={pageData[learnMoreIndex]} />}
				<ContactUs />
				<button onClick={this.handleBack}>Back</button>
			</div>
		) 
	}
}

export default LearnMore;